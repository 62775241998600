.home {
    .carousel {
        .carousel-list-1 {
            height: 800px;
            text-align: center;
            background: url('../../assets/images/home_banner01_bgd.png');

            .carousel-title {
                margin-top: 160px;
                color: #fff;
                font-size: 48px;
            }

            .txt {
                line-height: 86px;
                color: #fff;
                font-size: 20px;
            }

            .total-num {
                width: 40%;
                margin: 100px auto;
                display: flex;
                justify-content: space-between;

                .list-num {
                    position: relative;
                    width: 104px;
                    height: 180px;
                    line-height: 180px;
                    text-align: center;
                    border: 2px solid #FFFFFF;
                    border-radius: 8px;
                    font-size: 120px;
                    font-family: 'DIN-Medium';
                    color: #fff;
                }

                .list-num::before {
                    content: '';
                    width: 100px;
                    height: 90px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    background-color: #84EDF1;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    opacity: 0.5;
                }

                .num {
                    display: inline-block;
                    background-repeat: repeat-y;
                    background-position: center 0;
                    background-size: 1.5rem 30rem;
                    width: 1.5rem;
                    height: 3rem;
                }

            }
        }

        .carousel-list-2 {
            height: 800px;
            background: url('../../assets/images/home_banner02_bgd.png');
        }

        .carousel-list-3 {
            height: 800px;
            background: url('../../assets/images/home_banner03_bgd.png');
        }
    }

    .info-1 {
        height: 820px;
        text-align: center;
        background-color: #F0F0F0;
        overflow: hidden;

        .info-1-title {
            margin-top: 100px;
            color: #333;
            font-size: 48px;
        }

        .txt {
            width: 500px;
            margin: 20px auto;
            font-size: 16px;
            color: #666;
        }

        .info-img {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            cursor: pointer;

            .info-img-list {
                transition: all 0.8s;
            }

            .info-img-list:hover {
                transform: scale(1.2);
            }
        }

    }

    .info-2 {
        height: 860px;
        text-align: center;
        background: url('../../assets/images/home_info01_bgd.png');
        overflow: hidden;

        .info-2-title {
            margin-top: 100px;
            color: #fff;
            font-size: 48px;
        }

        .txt {
            width: 500px;
            margin: 20px auto;
            font-size: 16px;
            color: #fff;
        }

        .info-img {
            padding: 0 150px;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            .img-1 {
                transition: all 0.8s;

                transform: scale(0.8) skew(0deg, -10deg) translate(50px, 40px);
            }

            .img-2 {
                transition: all 0.8s;
                transform: scale(0.8) skew(0deg, 20deg) translate(10px, 50px);
                ;
            }

            // .img-1:hover,
            // .img-2:hover {
            //     transform: skew(0deg, 0deg);
            //     transform: scale(1);
            // }
        }
    }

    .advantage {
        height: 524px;
        background-color: #F0F0F0;
        text-align: center;
        overflow: hidden;

        .title {
            margin-top: 50px;
            font-size: 16px;
            color: #999;
        }

        .txt {
            margin-bottom: 80px;
            font-size: 48px;
            color: #333;
        }

        .advantage-img {
            width: 40%;
            margin: auto;
            display: flex;
            justify-content: space-between;
            text-align: center;

            .advantage-img-list {
                width: 200px;
                cursor: pointer;

                .list-img {
                    height: 160px;
                    transition: all 0.4s;
                }

                .list-txt {
                    color: #333;
                    font-size: 24px;
                    line-height: 60px;
                }
            }

            .advantage-img-list:hover .list-img {
                transform: rotate(360deg);
            }
        }
    }

    .achievement {
        height: 900px;
        text-align: center;
        background: url('../../assets//images/about_pic03.png');

        .title {
            color: #fff;
            font-size: 48px;
            line-height: 400px;
        }

        .advantage-img {
            padding: 0 150px;
            display: flex;
            justify-content: space-around;
            transition: all 0.8s;

            .advantage-img-list {
                position: relative;
                width: 310px;
                height: 424px;
                padding: 50px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                background-color: #fff;

                .list-txt {
                    font-size: 16px;
                    color: #192752;
                }

                .list-num {
                    font-size: 48px;
                    color: #192752;
                }

                .list-bg {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 310px;
                    background-color: #333;

                }
            }

            .advantage-img-list:hover {
                cursor: pointer;
                background-color: #8c8c8c;

                .list-txt,
                .list-num {
                    color: #fff;
                }
            }
        }


    }

    .site {
        height: 880px;
        text-align: center;
        background: url('../../assets/images/home_scenery_bgd.png');

        .title {
            line-height: 200px;
            font-size: 48px;
            color: #333;
        }

        .site-nav {
            margin-bottom: 30px;
            display: flex;
            justify-content: center;

            .nav-list {
                width: 260px;
                height: 80px;
                line-height: 80px;
                text-align: center;
                transition: all 0.8s;
            }

            .nav-list:hover {
                cursor: pointer;
                color: #fff;
                background-color: #192752;
            }
        }

        .site-content {
            width: 1220px;
            padding: 30px;
            margin: 0 auto;
            display: flex;
            background-color: #fff;
            overflow: hidden;

            .content-list {
                width: 100%;
                display: flex;
                justify-content: space-between;
                transition: all 0.8;

                .list-left {
                    // width: 50%;
                    flex: 1;
                    color: #333;
                    text-align: left;

                    .location-name {
                        font-size: 24px;
                        line-height: 60px;
                    }

                    .desc {
                        font-size: 16px;
                    }
                }

                .list-right {
                    // width: 50%;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .echarts-list {
                        display: flex;
                        justify-content: space-around;
                    }
                }
            }
        }
    }

    .evaluation {
        padding: 0 100px;
        background-color: #fff;
        text-align: center;

        .evaluation-title {
            line-height: 120px;
            font-size: 48px;
            color: #444;
        }

        .txt {
            font-size: 16px;
            color: #444;
            margin-bottom: 50px;
        }

        .users {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .users-item {
                height: 64px;
                margin: 0 40px 50px;
                padding: 0 40px;
                text-align: left;
                line-height: 64px;
                background: #FFFFFF;
                box-shadow: 0px 8px 24px 0px rgba(121, 150, 183, 0.34);
                transition: all 0.8s;

                .username {
                    color: #0063C8;
                }
            }

            .users-item:hover {
                cursor: pointer;
                transform: scale(1.2);
            }
        }

    }
}