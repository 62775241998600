body,
div,
span,
header,
footer,
nav,
section,
aside,
article,
ul,
dl,
dt,
dd,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
i,
b,
textarea,
button,
input,
select,
figure,
figcaption {
    padding: 0;
    margin: 0;
    list-style: none;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    border: none;
    font-family: 'Microsoft Yahei', sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;

    &:focus {
        outline: none;
    }
}

.el-main {
    padding: 0;
}

// /* 设置滚动条的样式 */
// ::-webkit-scrollbar {
//     width: 0;
// }

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'] {
    -webkit-appearance: none;
}

textarea {
    -webkit-appearance: none;
}

html,
body {
    width: 100%;
}

.fillcontain {
    height: 100%;
    width: 100%;
}

.clear:after {
    content: '';
    display: block;
    clear: both;
}

.clear {
    zoom: 1;
}

.back_img {
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.margin {
    margin: 0 auto;
}

.left {
    float: left;
}

.right {
    float: right;
}

.hide {
    display: none;
}

.show {
    display: block;
}






.text-left {
    text-align: left;
}