.footer {
    background-color: #020E35;

    .content {
        width: 1280px;
        height: 190px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;

        .content-list {
            flex: 1;
            display: flex;
            justify-content: center;

            .list-right {
                padding-left: 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }

        .content-list:nth-child(1) .list-right .name {
            font-size: 24px;
        }
    }

}