.product {
    .section-1 {
        height: 960px;
        color: #fff;
        text-align: center;
        background: #0039AE url('../../assets/images/product_section_bg.png') no-repeat bottom;

        .title {
            color: #fff;
            font-size: 72px;
            line-height: 200px;
        }

        .txt {
            width: 810px;
            margin: 0 auto;
            font-size: 18px;
        }
    }
}